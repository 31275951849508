export default [
  {
        path: '/register',
        name: 'patients-register',
        component: () => import('@/views/patients/regiter-form/RegisterForm.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            //redirectIfLoggedIn: true,
        },
    },
    {
      path: '/patients',
      name: 'patients-read',
      component: () => import('@/views/patients/patients-list/PatientsList.vue'),
      meta: {
        resource: 'Patients',
        action: 'read',
      },
    },
    {
      path: '/patients/detail/:id',
      name: 'patients-detail',
      component: () => import('@/views/patients/patients-detail/PatientsDetail.vue'),
      meta: {
        resource: 'Patients',
        action: 'read',
      },
    },
    {
      path: '/patients/create',
      name: 'patients-create',
      component: () => import('@/views/patients/patients-form/PatientsForm.vue'),
      meta: {
        resource: 'Patients',
        action: 'create',
      },
    },
    {
      path: '/patients/edit/:id',
      name: 'patients-edit',
      component: () => import('@/views/patients/patients-form/PatientsForm.vue'),
      meta: {
        resource: 'Patients',
        action: 'update',
      },
    },
    {
        path: '/patients/group',
        name: 'patients-group-read',
        component: () => import('@/views/patients/group-list/GroupList.vue'),
        meta: {
          resource: 'Patients',
          action: 'read',
        },
    },
    {
      path: '/patients/tag',
      name: 'patients-tag-read',
      component: () => import('@/views/patients/tag-list/TagList.vue'),
      meta: {
        resource: 'Patients',
        action: 'tag-read',
      },
    },
  ]
  